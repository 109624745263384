import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import scrollList from '@mixins/scrollList';
import getPageList from '@mixins/getPageList';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
import DateRangePicker from './components/DateRangePicker';
import moment from 'moment';
import { getStockInList } from '@api/assets';
const sourceTypeList = [{
  label: "采购入库",
  value: 1
}, {
  label: "调拨入库",
  value: 2
}];
const sourceTypeMap = _mapValues(_keyBy(sourceTypeList, i => i.value), j => j.label);
const stockMethodList = [{
  label: "部分入库",
  value: 1
}, {
  label: "全部入库",
  value: 2
}];
const stockMethodMap = _mapValues(_keyBy(stockMethodList, i => i.value), j => j.label);
export default {
  name: 'AssetsInLog',
  mixins: [common, scrollList, getPageList],
  components: {
    DateRangePicker
  },
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  data() {
    return {
      // initParams: { // 初始化参数
      //
      // },
      searchKey: {
        page: 1,
        size: 20,
        times: null
      },
      // 搜索参数
      listData: [],
      sourceTypeMap,
      stockMethodMap
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.resetParams();
      await this.getListData();
    },
    goDetail(item) {
      if (item.sourceType == 1) {
        this.$router.push({
          name: 'AssetsInLogDetail',
          query: {
            id: item.id
          }
        });
      } else if (item.sourceType == 2) {
        this.$router.push({
          name: 'AssetsInLogDetail2',
          query: {
            id: item.id
          }
        });
      }
    },
    onDateChange() {
      this.initData();
    },
    async getListData({
      isPullDown,
      isShowLoading
    } = {
      isPullDown: false,
      isShowLoading: true
    }) {
      // 获取列表数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          page,
          size,
          times
        } = this.searchKey;
        let {
          dcProjectId
        } = this.projectInfo;
        let response = await assetsApi.getStockInList({
          pageNum: page,
          pageSize: size,
          projectId: dcProjectId,
          createTimeStart: times && times[0] ? moment(times[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          createTimeEnd: times && times[1] ? moment(times[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
        });
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '200') {
          // 请求成功
          let hasMore = false;
          let resultData = result.data || [];
          //todo 分页问题
          hasMore = false; //page < result.data.length
          if (!isPullDown) {
            this.listData.push(...resultData);
          } else {
            this.listData = [...resultData];
          }
          if (!hasMore) {
            this.isLastPage = true;
          }
          await this.handleCallback({
            isError: false,
            hasMore
            // noDataMessage: ' ',
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    }
  }
};